<script>
import { required, maxLength } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";

export default {
  components: { validationMessages },
  props: ['youTubeSetupData'],
  data() {
    return {
      tenant: '',
      id: '',
      premium: '',
      channel: '',
      team_type: '',
      premium_status: '',
      selectedType: '',
      tenants_options: [],
      type: [
        'playlist',
        'channel'
      ],
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      error: null,
      errorChannel: null,
    };
  },

  validations: {
    id: {
      required,
    },
    tenant: {
      required,
    },
    channel: {
      required,
      maxLength: maxLength(100),
    },
    type: {
      required,
    }
  },

  watch: {
    youTubeSetupData(newVal) {
      this.id = newVal.id;
      this.tenant = newVal.tenant;
      this.channel = newVal.channel;
      this.selectedType = newVal.type;
      this.premium_status = newVal.premium;
    }
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.submitted = false;
    },

    async getTenants() {
      try {
        const response = await Monitor.getTenants();
        this.tenants_options = response.data.tenants.map(tenant => ({ value: tenant, text: tenant }));
      } catch (error) {
        console.error("Error fetching tenants:", error);
      }
    },
    async getChannel() {
      try {
        const response = await Monitor.getChannel(this.tenant);
        if (response && response.data) {
          this.channel = response.data
        }
      } catch (error) {
        console.error('Error fetching tenant dashboards:', error);
        this.errorChannel = error.response?.data?.error || '';
      }
    },
    async onTenantChange() {
      this.error = '';
      this.channel = '';
      if (this.tenant) {
        await this.getChannel();
      }
    },

    async updateYouTubeSetup() {
      this.tryingToSubmit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      let formData = {
        id: this.id,
        tenant: this.tenant,
        channel: this.channel,
        type: this.selectedType,
        premium_status: this.premium_status

      }
      try {
        const response = await Monitor.updateYouTubeSetup(this.youTubeSetupData.id, formData);
        if (response.error) {
          this.failedmsg(response.error);
        } else {
          this.successmsg(response.message);
          this.closeModal();
          this.refreshData();
        }
      } catch (error) {
        this.error = error.response?.data?.error || "An error occurred";
        this.failedmsg(this.error);
      } finally {
        this.tryingToSubmit = false;
        this.showLoader = false;
      }
    },

    successmsg(message) {
      Swal.fire("Success", message, "success");
    },

    failedmsg(message) {
      Swal.fire("Error", message, "error");
    },

    refreshData() {
      this.$emit("onRefresh");
    },

    resetForm() {
      this.id = "";
      this.tenant = "";
      this.channel = "";
      this.selectedType = "";
      this.premium_status = "";
      this.$v.$reset();
    },

    modalShown(){
      this.getTenants()
    }
  },
};
</script>

<template>
  <b-modal  @shown="modalShown" id="edit_youtube_setup" size="l" v-model="showModal" @hidden="resetForm" title="Edit YouTube Setup" title-class="font-18">
    <form @submit.prevent="updateYouTubeSetup">
      <div class="row" v-if="youTubeSetupData">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <div class="col-sm-12">
              <b-form-group label="ID" label-for="formrow-id-input" class="mb-3">
                <b-form-input
                    v-model="id"
                    :class="{ 'is-invalid': submitted && $v.id.$error }"
                    class="form-input"
                ></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'ID'" :validationName="$v.id"></validationMessages>
              </b-form-group>

              <b-form-group label="Select Tenant" label-for="formrow-tenant-input" class="mb-3">
                <b-form-select
                    v-model="tenant"
                    :options="tenants_options"
                    :class="{ 'is-invalid': submitted && $v.tenant.$error }"
                    class="form-select"
                    @change="onTenantChange"
                ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Tenants'" :validationName="$v.tenant"></validationMessages>
              </b-form-group>

              <b-form-group label="Channel" label-for="formrow-channel-input" class="mb-3">
                <small class="text-muted" v-if="this.errorChannel ">Please insert YouTube Channel</small>
                <b-form-input
                    v-model="channel"
                    :class="{ 'is-invalid': submitted && $v.channel.$error }"
                    class="form-input"
                    :readonly="!this.errorChannel"
                ></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Channel'" :validationName="$v.channel"></validationMessages>
              </b-form-group>

              <b-form-group label="Select Type" label-for="formrow-type-input" class="mb-3">
                <b-form-select
                    v-model="selectedType"
                    :options="type"
                    :class="{ 'is-invalid': submitted && $v.selectedType.$error }"
                    class="form-select"
                ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Type'" :validationName="$v.selectedType"></validationMessages>
              </b-form-group>

              <b-form-checkbox-group>
                <div class="form-check form-switch form-switch-lg mb-3" >
                  <input v-model="premium_status"
                         type="checkbox"
                         class="form-check-input"
                         id="premiumStatus"
                  />
                  <label class="form-check-label" for="premiumStatus">Premium Status</label>
                </div>
              </b-form-checkbox-group>
              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="updateYouTubeSetup" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>
        Submit
      </b-button>
    </template>
  </b-modal>
</template>
