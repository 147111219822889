<script>
import Monitor from '@/services/Monitor';
import General from '@/services/General';
import validationMessages from '@/components/validations';
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  components:{
    validationMessages
  },
  data() {
    return {
      showLoader: false,
      showModal: false,
      tryingToSubmit: false,
      submitted: false,
      tenants:'',
      tenant: null ,
      id: '' ,
      type: [
        'playlist',
        'channel'
      ],
      selectedType: '',
      tenants_options: [] ,
      premium_status : false,
      errorChannel : '',
      channel: '',
      csrf_token: localStorage.getItem('csrf_token'),

    };
  },
  validations: {
    id: {
      required,
    },
    tenant: {
      required,
    },
    channel: {
      required,
      maxLength: maxLength(100),
    },
    type: {
      required,
    }
  },


  methods: {
    refreshData() {
      this.$emit('onRefresh');
    },
    async onTenantChange() {
      this.error = '';
      this.channel = '';
      if (this.tenant) {
        await this.getChannel();
      }
    },
    async getTenants() {
      try {
        this.showLoader = true;
        const response = await Monitor.getTenants();
        this.tenants_options = response.data.tenants.map(tenant => ({
          text: tenant,
          value: tenant,
        }));
      } catch (error) {
        console.log('Error fetching tenants:', error);
      } finally {
        this.showLoader = false;
      }
    },

    async getChannel() {
      try {
        const response = await Monitor.getChannel(this.tenant);
        if (response && response.data) {
          this.channel = response.data
        }
      } catch (error) {
        console.error('Error fetching tenant dashboards:', error);
        this.errorChannel = error.response?.data?.error || '';
      }
    },


    async createYouTubeSetup() {
      this.tryingToSubmit = true;
      this.submitted = true;
      this.showLoader = true;

      this.$v.$touch();

      if (this.$v.$invalid || !this.tenant) {
        this.showLoader = false;
        return;
      }

      try {
        this.showLoader = true;

        const requestData = {
          id: this.id,
          tenant: this.tenant,
          channel: this.channel,
          type: this.selectedType,
          premium: this.premium_status === true ? 1 : 0,

        };

        const response = await Monitor.createYouTubeSetup(requestData);

        this.successmsg('Tenant Dashboard Created!');
        this.refreshData();
        this.closeModal();
      } catch (error) {
        this.error = error.response?.data?.error || "An error occurred";
        this.failedmsg(this.error);
      } finally {
        this.tryingToSubmit = false;
      }
    },


    closeModal() {
      this.showModal = false;
    },

    resetProps() {
      this.submitted = false;
      this.tenant = '';
      this.channel = '';
      this.id = '';
      this.selectedType = '';
      this.premium_status = false;
    },

    async modalShown() {
      await this.getTenants();

    }
  },

};
</script>

<template>
  <b-modal @shown="modalShown" id="add_youtube_setup" v-model="showModal" @hidden="resetProps" title="Add YouTube Setup" title-class="font-18" size="md">
    <form @submit.prevent="createYouTubeSetup" v-if="!showLoader">
          <b-form-group label="ID" label-for="formrow-id-input" class="mb-3">
            <b-form-input
                v-model="id"
                :class="{ 'is-invalid': submitted && $v.id.$error }"
                class="form-input"
            ></b-form-input>
            <validationMessages v-if="submitted" :fieldName="'ID'" :validationName="$v.id"></validationMessages>
          </b-form-group>

          <b-form-group label="Select Tenant" label-for="formrow-tenant-input" class="mb-3">
            <b-form-select
                v-model="tenant"
                :options="tenants_options"
                :class="{ 'is-invalid': submitted && $v.tenant.$error }"
                class="form-select"
                @change="onTenantChange"
            ></b-form-select>
            <validationMessages v-if="submitted" :fieldName="'Tenants'" :validationName="$v.tenant"></validationMessages>
          </b-form-group>

      <b-form-group label="Channel" label-for="formrow-channel-input" class="mb-3">
        <small class="text-muted" v-if="this.errorChannel ">Please insert YouTube Channel</small>
        <b-form-input
            v-model="channel"
            :class="{ 'is-invalid': submitted && $v.channel.$error }"
            class="form-input"
            :readonly="!this.errorChannel"
        ></b-form-input>
        <validationMessages v-if="submitted" :fieldName="'Channel'" :validationName="$v.channel"></validationMessages>
      </b-form-group>



      <b-form-group label="Select Type" label-for="formrow-type-input" class="mb-3">
        <b-form-select
            v-model="selectedType"
            :options="type"
            :class="{ 'is-invalid': submitted && $v.selectedType.$error }"
            class="form-select"
        ></b-form-select>
        <validationMessages v-if="submitted" :fieldName="'Type'" :validationName="$v.selectedType"></validationMessages>
      </b-form-group>

      <b-form-checkbox-group>
        <div class="form-check form-switch form-switch-lg mb-3" >
          <input v-model="premium_status"
                 type="checkbox"
                 class="form-check-input"
                 id="statusSponsor"
          />
          <label class="form-check-label" for="statusSponsor">Premium Status</label>
        </div>
      </b-form-checkbox-group>

    </form>
    <div v-else>
      <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
        <div class="row">
          <div class="col-12">
            <div class="row  align-items-center justify-content-center">
              <b-spinner large></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="createYouTubeSetup" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>
        Submit
      </b-button>
    </template>
  </b-modal>
</template>
